@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

.locations {
    .location-header {
        text-align: center;
        text-transform: uppercase;

        .second-h3 {
            padding-bottom: 0;
        }

        @extend .border-bottom;
        @extend .pb-2;

        @include media-breakpoint-down(sm) {
            padding-bottom: 0.937rem;

            .second-h3 {
                width: 80%;
            }
        }
    }

    .location-links {
        list-style: none;
        padding-left: 0;
        text-align: center;

        li {
            margin-bottom: 10px;

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        @extend .pt-2;
    }

    .map-desc {
        font-family: $font-family-sans-serif;
        font-size: $font-size-smallest;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 2px;

        @extend .mt-3;
    }

    .map-link {
        text-align: center;

        @extend .mt-3;
    }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    .locations {
        .row > .col-6:first-of-type {
            padding-right: 10px;
        }

        .row > .col-6:last-of-type {
            padding-left: 10px;
        }
    }
}
